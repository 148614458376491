const countdown = () => {
  setTimeout(redirect, 5000)
}

const redirect = () => {
  const url = document.documentElement.dataset.url
  if (process.env.NODE_ENV == 'development') {
    console.log(`Redirect to ${url} skipped`)
  } else {
    document.location.href = url
  }
}

countdown()